<template>
  <div class="visitor-portrait">
    <div class="echarts-image">
      <div class="image-left image-common">
        <div style="width:100%;height:40px">
          <el-row class="left-title">
            <el-col :span="8" class="font-18bb">身份属性分布</el-col>
            <el-col :span="8" class="font-14bb">
              用户总数
              <span class="font-14bl">{{identities.user_count}}</span>人
            </el-col>
            <el-col :span="8" class="font-14bb">
              身份确认人数
              <span class="font-14bl">{{identities.user_identity_count}}</span>人
            </el-col>
          </el-row>
        </div>
        <div id="leftEcharts" style="width:100%;height:260px"></div>
      </div>
      <div class="image-right image-common">
        <div style="width:100%;height:40px">
          <el-row class="left-title">
            <el-col :span="6" class="font-18bb">政策需求</el-col>
            <el-col :span="6" class="font-14bb">
              提交人数
              <span class="font-14bl">{{requirements.user_count}}</span>个
            </el-col>
            <el-col :span="6" class="font-14bb">
              提交次数
              <span class="font-14bl">{{requirements.questionnaire_count}}</span>次
            </el-col>
            <el-col :span="6" class="font-14bb">
              需求总数
              <span class="font-14bl">{{requirements.all_demands_count}}</span>个
            </el-col>
          </el-row>
        </div>
        <div id="rightEcharts" style="width:100%;height:260px"></div>
      </div>
    </div>
    <div class="content">
      <h2 class="title">访客列表</h2>
      <div class="search">
        <el-input
          clearable
          @change="search"
          size="small"
          v-model="searchValue"
          placeholder="输入关键词搜索"
        ></el-input>
      </div>
      <el-table border :data="tableData" style="width: 100%">
        <el-table-column align="center" prop="id" label="ID"></el-table-column>
        <el-table-column min-width="100" align="center" prop="date" label="头像">
          <template slot-scope="scope">
            <img v-if="scope.row.avatar" height="60" width="80" :src="scope.row.avatar" alt />
            <img v-else height="60" width="80" src="@/assets/images/暂无图片.png" alt />
          </template>
        </el-table-column>
        <el-table-column min-width="100" align="center" prop="name" label="姓名"></el-table-column>
        <el-table-column min-width="100" align="center" prop="nickname" label="昵称"></el-table-column>
        <el-table-column min-width="100" align="center" prop="company_name" label="公司"></el-table-column>
        <el-table-column min-width="100" align="center" prop="brand" label="品牌"></el-table-column>
        <el-table-column min-width="100" align="center" prop="career" label="职位"></el-table-column>
        <el-table-column min-width="110" align="center" prop="mobile" label="手机"></el-table-column>
        <el-table-column min-width="100" align="center" prop="email" label="邮箱"></el-table-column>
        <el-table-column min-width="100" align="center" prop="wechat" label="微信"></el-table-column>
        <el-table-column min-width="100" align="center" prop="created_at" label="注册时间"></el-table-column>
        <el-table-column min-width="100" align="center" prop="login_time" label="最新登录时间"></el-table-column>
        <el-table-column min-width="100" fixed="right" align="center" prop="address" label="操作">
          <template slot-scope="scope">
            <el-button @click="visitorsClick(scope.row)" type="primary" size="small">访客画像</el-button>
          </template>
        </el-table-column>
      </el-table>
      <page
        style="text-align:center"
        :total="page.total"
        :current_page="page.current_page"
        @currentPageChange="currentPageChange"
      />
    </div>
    <el-dialog :title="visitorName+'的用户画像'" center width="50%" :visible.sync="userDialog">
      <div class="user-portrait">
        <div class="portrait-item" v-for="(item,i) in portraits" :key="i">
          <div class="span-fa">
            <span class="span-item1">{{item.title}}</span>
            <span class="span-item2">({{item.count}})次</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import page from "@/components/PageComponents";
import echarts from "echarts";
export default {
  name: "",
  components: { page },
  data() {
    return {
      leftEcharts: null,
      rightEcharts: null,
      organ_id: 0, //机构id
      searchValue: "", //搜索
      tableData: [], //表格数据
      page: { current_page: 0, total: 0, per_page: 0 }, //分页数据
      userDialog: false, //用户画像弹窗
      portraits: [], //用户画像数据
      visitorName: "", //访客名称
      identities: {}, //身份属性分布数据
      requirements: {}, //政策需求
      identityOption: {
        title: {
          text: "",
          subtext: "",
          left: "center"
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          icon: "circle",
          left: "center",
          top: "top",
          data: []
        },
        series: [
          {
            name: "身份属性",
            type: "pie",
            radius: [30, 110],
            center: ["50%", "60%"],
            roseType: "area",
            data: []
          }
        ]
      },
      policyOption: {
        title: {
          text: "",
          subtext: "",
          left: "center"
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          icon: "circle",
          left: "center",
          top: "top",
          data: []
        },
        series: [
          {
            name: "政策需求",
            type: "pie",
            radius: [30, 110],
            center: ["50%", "60%"],
            roseType: "area",
            data: []
          }
        ]
      }
    };
  },
  created() {
    this.organ_id = sessionStorage.organizationId;
    this.getVisitorsList(this.organ_id, 1);
    this.getIdentitiesCount();
    this.getDemandsCount();
  },
  mounted() {
    this.leftEcharts = this.$echarts.init(
      document.getElementById("leftEcharts")
    );
    this.rightEcharts = this.$echarts.init(
      document.getElementById("rightEcharts")
    );
    this.leftEcharts.setOption(this.identityOption, true);
    this.rightEcharts.setOption(this.policyOption, true);
    //监听浏览器大小缩放
    window.addEventListener("resize", () => {
      if (this.leftEcharts && this.leftEcharts.resize) {
        this.leftEcharts.resize();
      }
      if (this.rightEcharts && this.rightEcharts.resize) {
        this.rightEcharts.resize();
      }
    });
  },
  methods: {
    //访客列表
    async getVisitorsList(organ_id, page, q, limit) {
      const { data: res } = await this.$http.get(
        this.URL.adminUrl.visitorsList,
        {
          params: { organ_id, page, q, limit }
        }
      );
      this.tableData = res.data.data;
      this.page = res.data.meta;
    },
    //分页切换
    currentPageChange(p) {
      this.getVisitorsList(this.organ_id, p, this.searchValue);
    },
    //搜索
    search(value) {
      this.getVisitorsList(this.organ_id, "", value);
    },
    //用户身份属性统计
    async getIdentitiesCount() {
      const { data: res } = await this.$http.get(
        this.URL.adminUrl.identitiesCount,
        {}
      );

      this.identities = res.data;
      let arr = this.identities.proportion.map(item => item.title);
      let arr2 = this.identities.proportion.map(item => {
        return { name: item.title, value: item.sort_proportion };
      });
      this.identityOption.legend.data = arr;
      this.identityOption.series[0].data = arr2;
    },
    //政策需求统计
    async getDemandsCount() {
      const { data: res } = await this.$http.get(
        this.URL.adminUrl.demandsCount,
        {}
      );

      this.requirements = res.data;
      let arr = this.requirements.proportion.map(item => item.title);
      let arr2 = this.requirements.proportion.map(item => {
        return { name: item.title, value: item.sort_proportion };
      });
      this.policyOption.legend.data = arr;
      this.policyOption.series[0].data = arr2;
    },
    //单个用户的用户画像
    async visitorsClick(data) {
      this.userDialog = true;
      this.visitorName = data.name;
      const { data: res } = await this.$http.get(
        this.URL.adminUrl.singleActions + data.id + "/actions",
        { params: { organ_id: this.organ_id } }
      );
      this.portraits = res.data;
    }
  },
  watch: {
    //观察左边option的数据变化
    identityOption: {
      handler(newVal, oldVal) {
        if (this.leftEcharts) {
          if (newVal) {
            this.leftEcharts.setOption(newVal);
          } else {
            this.leftEcharts.setOption(oldVal);
          }
        } else {
          this.init();
        }
      },
      deep: true //对象内部属性的监听，关键。
    },
    //观察右边option的数据变化
    policyOption: {
      handler(newVal, oldVal) {
        if (this.rightEcharts) {
          if (newVal) {
            this.rightEcharts.setOption(newVal);
          } else {
            this.rightEcharts.setOption(oldVal);
          }
        } else {
          this.init();
        }
      },
      deep: true //对象内部属性的监听，关键。
    }
  }
};
</script>
<style lang="less">
.visitor-portrait {
  padding: 10px;
  // 头部
  .echarts-image {
    display: flex;
    justify-content: space-between;
    .image-common {
      width: 46%;
      height: 300px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }
    .left-title {
      height: 100%;
      text-align: center;
      display: flex;
      align-items: center;
    }
  }
  //内容
  .content {
    margin-top: 50px;
    .title {
      text-align: center;
    }
    .search {
      width: 30%;
      margin: 10px 0;
    }
    .el-button {
      background-color: #3970ec;
    }
  }
  //弹窗
  .el-dialog {
    height: 70vh;
    background-image: url("../../../assets/images/user_portrait.png");
    background-position: 50% 50%;
    background-size: 50%;
    background-repeat: no-repeat;
    .el-dialog__title {
      font: bold 26px "苹方 粗体";
    }
    .user-portrait {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .portrait-item {
        width: 35%;
        text-align: center;
        margin: 2% 0;
        .span-item1 {
          font: bold 18px "苹方 粗体";
          color: #333333;
          border-bottom: 1px dashed #979797;
          padding-bottom: 5px;
        }
        .span-item2 {
          font: bold 18px "苹方 粗体";
          color: #3970ec;
          border-bottom: 1px dashed #979797;
          padding-bottom: 5px;
        }
      }
    }
  }
  //字体公共样式
  .font-18bb {
    font: bold 18px "苹方 粗体";
    color: #485465;
  }
  .font-14bb {
    font: 14px "苹方 中等";
    color: #485465;
  }
  .font-14bl {
    font: bold 14px "苹方 特粗";
    color: #185d8d;
  }
}
</style>